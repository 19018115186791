<template>
    <div>
        <Menu class="mb-4"/>
        <AsideFilter />
    </div>
</template>

<script>
import Menu from '@/components/promo/Menu.vue'
import AsideFilter from '@/components/promo/AsideFilter.vue'
export default {
    name: "Aside",
    components: {
        Menu,
        AsideFilter
    }
}
</script>

<style>

</style>