<template>
  <v-app>
    <div class="promo-layout">
      <Header />
      <div class="content mb-10">
        <v-breadcrumbs  
        divider=">"
        :items="breadcrumbs">
          <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :href="item.href"
              :to="item.to"
              :disabled="item.disabled"
            >
              <svg v-if="item.text === 'home'" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0L0 4.54545V10H10V4.54545L5 0ZM5 2.57273L8 5.3V8.18182H6V6.36353H4V8.18182H2V5.3L5 2.57273Z" fill="#858585"/>
              </svg>
              <span v-else>{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>

        <TopLinks v-if="$route.name === 'PromoList' || 
          $route.name === 'PromoCarousel'" 
        />

        <v-row 
          class="pt-8 my-0 promo__row align-start"
        >
            <v-col 
            cols="12"
            sm="12"
            md="4"
            class="py-0 promo__aside-wrap"
            >
              <div  class="promo__aside">
                  <Aside/>
              </div>
            </v-col>
             <v-col 
            cols="12"
            sm="12"
            md="8"
           class="py-0"
            >
            <router-view />
            </v-col>
        </v-row>
       
      </div>
      <Footer/>
    </div>  
  </v-app>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Aside from '../components/promo/Aside.vue'
import TopLinks from '@/components/promo/TopLinks'
// import Menu from '../components/promo/Menu.vue'
export default {
  components: { 
    Header,
    Footer,
    Aside,
    TopLinks,
    // Menu, 
  },
  name: "promo",
  data:(vm) => ({
    
  }),
  computed: {
    country() {
        switch(this.$route.params.country) {
        case 'ru':
            return "Россия"
        case 'ua':
            return "Украина"
        case 'kz':
            return "Казахстан"
        }
    },
    breadcrumbs() {
        return [
            {
                text: "home",
                to: { 
                  name: "PromoList", 
                  params: { country: this.$route.params.country } 
                }
            },
            {
                text: this.country,
                to: { 
                  name: "PromoList", 
                  params: { country: this.$route.params.country } 
                }
            },
            {
                text: "Страница промо-акций",
                to: { 
                  name: "PromoList", 
                  params: { country: this.$route.params.country } 
                }
            }
        ]
    } 
  },
}
</script>

<style scoped lang="scss">
    .promo-layout {
        width: 100%;
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        overflow-y: auto;
        padding-top: 75px;

        & .header {
          position: fixed;
          width: 100%;
          left: 0;
          z-index: 310;
        }

        & .header {
          top: 0;
        }
    }

    .promo__aside {
      height: calc(100vh - 75px);
      overflow-y: auto;

      @media screen and (max-width: 960px) {
          height: auto;
      }
    }

    .promo__aside-wrap {
      position: sticky;
      top: 0;
      @media screen and (max-width: 960px) {
        position: relative;
        z-index: 3;
      }
     
    }
</style>

<style lang="scss">
  
</style>